<template>
    <div id="bi-list">

        <el-form :inline="true" :model="form" class="demo-form-inline">
            <el-form-item label="ID">
                <el-input v-model="form.id" placeholder="ID"></el-input>
            </el-form-item>
            <el-form-item label="发起人ID">
                <el-input v-model="form.userId" placeholder="ID"></el-input>
            </el-form-item>
            <el-form-item label="发起人">
                <el-input v-model="form.username" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item label="期号">
                <el-input v-model="form.periodId" placeholder="期号"></el-input>
            </el-form-item>
            <el-form-item label="彩种">
                <el-select v-model="form.lotteryType" placeholder="请选择" clearable>
                    <el-option v-for="lotteryType in Object.keys(LOTTERY_TYPE)" :key="lotteryType"
                        :label="LOTTERY_TYPE[lotteryType]" :value="lotteryType">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开奖状态">
                <el-select v-model="form.betResult" placeholder="请选择" clearable>
                    <el-option v-for="betResult in Object.keys(BET_RESULT)" :key="betResult"
                        :label="BET_RESULT[betResult]" :value="betResult">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="派奖状态">
                <el-select v-model="form.prizeStatus" placeholder="请选择" clearable>
                    <el-option v-for="prizeStatus in Object.keys(PRIZE_STATUS)" :key="prizeStatus"
                        :label="PRIZE_STATUS[prizeStatus]" :value="prizeStatus">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="loadList">查询</el-button>
            </el-form-item>
        </el-form>

        <el-table :data="page.list" border>
            <el-table-column prop="id" label="ID">
                <template v-slot="row">
                    {{ row.row.id }}
                    <el-tag v-if="row.row.rpId != null">追号</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="userId" label="发起人">
                <template v-slot="row">
                    [{{ row.row.userId }}]{{ row.row.email }}
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发起时间">
            </el-table-column>
            <el-table-column prop="periodId" label="期号">
            </el-table-column>
            <el-table-column label="彩种">
                <template v-slot="row">
                    {{ LOTTERY_TYPE[row.row.lotteryType] }}
                </template>
            </el-table-column>
            <el-table-column prop="amount" label="金额">
            </el-table-column>
            <el-table-column label="开奖结果">
                <template v-slot="row">
                    {{ BET_RESULT[row.row.betResult] }}
                    <span v-if="row.row.betResult == 'WIN'"> - {{ row.row.prize }}</span>
                </template>
            </el-table-column>
            <el-table-column label="派奖结果">
                <template v-slot="row">
                    <span v-if="row.row.prizeStatus != null">{{ PRIZE_STATUS[row.row.prizeStatus]
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template v-slot="row">

                    <el-popover placement="left" title="投注详情" trigger="hover" width="800">
                        <Bingo18Content v-if="row.row.lotteryType == 'BINGO18'" :betContent="row.row.betContent">
                        </Bingo18Content>
                        <el-button slot="reference">投注详情</el-button>
                    </el-popover>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="text-align: center" :page-count="page.totalPage" :current-page="form.pageNo"
            layout="prev, pager, next" @current-change="changePage">
        </el-pagination>
    </div>
</template>
<script>
import { list } from './bi'
import { LOTTERY_TYPE, PLAY_TYPE, PRIZE_STATUS, BET_RESULT } from "../../util/constants";
import Bingo18Content from '../../components/Bingo18Content.vue'
export default {
    name: "BetInfoList",
    components: {
        Bingo18Content,
    },
    data() {
        return {

            LOTTERY_TYPE,
            PLAY_TYPE,
            PRIZE_STATUS,
            BET_RESULT,

            form: {
                pageNo: 1,
                pageSize: 20,
                lotteryType: null,
                playType: null,
                prizeStatus: null,
                userId: this.$route.query.userId,
                periodId: this.$route.query.periodId,
                id: this.$route.query.id,
                username: null,

            },

            page: {
                list: null,
                totalPage: null,
            },
        };
    },

    methods: {
        loadList() {
            list(this.form).then((data) => {
                this.page = data;
            });
        },

        changePage(val) {
            this.form.pageNo = val;
            this.loadList();
        },

    },

    created() {
        this.loadList();
    },

};
</script>